<template>
  <!-- 引导页 -->
  <div style="min-height: 100vh;">
    <heard ref="" />
    <div class="main">
      <!-- <div class="staticTxt">
        {{ staticTxt }}
      </div> -->
      <div class="main-content">
        <div class="main-content-lbox">
          <div class="main-content-lbox-title">
            Vllshop<span style="color: #028BFF;font-size: 38px;">5</span>分钟快速建站
            <div class="main-content-lbox-title-subtitle">
              <span>{{ currenttxt }}</span>
            </div>
          </div>
          <div class="main-content-lbox-box">
            <div class="main-content-lbox-box-pic ">
              <img :src="currentNumberCircle" class="main-content-lbox-box-pic-draw" alt="">
              <!-- <img :src="currentNumber" class="main-content-lbox-box-pic-number" alt=""> -->
            </div>
            <div class="main-content-lbox-box-txt animated fadeIn">
              <div class="main-content-lbox-box-txt-textone">
                <span>{{ currentTxtone }}</span>
              </div>
              <div class="main-content-lbox-box-txt-texttwo" style="margin-left: 30%;" />
              <div class="main-content-lbox-box-txt-textthree" style="margin-top:30px">
                <span>{{ currentTxttwo }}</span>
              </div>
              <div class="main-content-lbox-box-txt-textthree">
                <span>{{ currentTxtthree }}</span>
              </div>
            </div>
          </div>
        </div>
        <div :key="key" class="main-content-rbox animated slideInRight">
          <img
            class="main-content-rbox-img"
            :src="currentImg"
            alt=""
          >
        </div>
      </div>
      <div>
        <el-button v-show="currentIndex!=0" class="bottom-btn" style="background-color: #fff;color: #0364FF;border: unset;" type="primary" @click="last()">
          上一步
        </el-button>
        <el-button v-show="currentIndex < imgList.length -1" :class="currentIndex==0?'bottom-btn-width':''" class="bottom-btn" style="background-color:#0364FF" type="primary" @click="next()">
          下一步
        </el-button>
        <el-button v-show="currentIndex === imgList.length -1" :disabled="lastItem.createStatus==0" class="bottom-btn" type="primary" @click="toShop()">
          {{ lastItem.createStatus==0?'正在创建中…':'我知道了' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import 'animate.css'
import heard from '../components/heard.vue'
import { getMd5Token, getShopListById, getUserInfo, shopList } from '@/api/register'

export default {
  components: {
    heard
  },
  data() {
    return {
      userInfo: {},
      lastItem: {
        createStatus: '',
        id: ''
      },
      lastItemTimer: '', // 定时器
      statusNum: 0, // 状态值
      key: 0,
      currentIndex: 0,
      currentImg: require('../../../assets/img/shopGuidance/shopGuidanceOne.png'),
      currentNumber: require('../../../assets/img/shopGuidance/numberOne.png'),
      currentNumberCircle: require('../../../assets/img/shopGuidance/imgNumberOne.png'),
      currenttxt: '第一步.',
      currentTxtone: '挑选心怡的店装',
      currentTxttwo: '快速搭建个性化前端模板任意选择',
      currentTxtthree: '下色调风格与品牌UI一致',

      imgList: [{
        txt: '第一步.',
        txtone: '挑选心怡的店装',
        txtTwo: '快速搭建个性化前端模板任意选择',
        txtThree: '下色调风格与品牌UI一致',
        imgNumber: require('../../../assets/img/shopGuidance/numberOne.png'),
        numberCircle: require('../../../assets/img/shopGuidance/imgNumberOne.png'),
        img: require('../../../assets/img/shopGuidance/shopGuidanceOne.png')
      },
      {
        txt: '第二步.',
        txtone: '快速添加商品',
        txtTwo: '快速添加商品',
        txtThree: '一件上架开启爆品新旅程',
        imgNumber: require('../../../assets/img/shopGuidance/numberTwo.png'),
        numberCircle: require('../../../assets/img/shopGuidance/imgNumberTwo.png'),
        img: require('../../../assets/img/shopGuidance/shopGuidanceTwo.png')
      },
      {
        txt: '第三步.',
        txtone: '选择性价比物流',
        txtTwo: '灵活配置，方案多地区设置',
        txtThree: 'Vllshop另外提供智能物流方案',
        imgNumber: require('../../../assets/img/shopGuidance/numberThree.png'),
        numberCircle: require('../../../assets/img/shopGuidance/imgNumberThree.png'),
        img: require('../../../assets/img/shopGuidance/shopGuidanceThree.png')
      },
      {
        txt: '第四步.',
        txtone: '设定收款方式',
        txtTwo: '安全，高效，支付便捷',
        txtThree: '选择您所信任的支付方式',
        imgNumber: require('../../../assets/img/shopGuidance/numberFour.png'),
        numberCircle: require('../../../assets/img/shopGuidance/imgNumberFour.png'),
        img: require('../../../assets/img/shopGuidance/shopGuidanceFour.png')
      },
      {
        txt: '第五步.',
        txtone: '配置专属域名',
        txtTwo: '自定义添加专属域名',
        txtThree: '提高网站客户粘性，提升专属度',
        imgNumber: require('../../../assets/img/shopGuidance/numberFive.png'),
        numberCircle: require('../../../assets/img/shopGuidance/imgNumberFive.png'),
        img: require('../../../assets/img/shopGuidance/shopGuidanceFive.png')
      }]
    }
  },
  watch: {
    lastItem(newValue) {
      if (!newValue) {
        return
      }
      if (this.lastItem.id) {
        if (parseInt(newValue.createStatus) === 0 && this.lastItemTimer === '') {
          this.lastItemTimer = setInterval(() => {
            getShopListById(newValue.id).then((res) => {
              this.lastItem = res.data
              if (parseInt(res.data.createStatus) === 1) {
                this.statusNum = 1
                clearInterval(this.lastItemTimer)
              }
            })
          }, 2000)
        }
      }
    }
  },
  mounted() {
    this.getShopList()
    getUserInfo().then((res) => {
      if (res.code === 0) {
        this.userInfo = res.data
      }
    })
  },
  methods: {
    getShopList() {
      shopList().then(res => {
        const shopList = res.data
        this.lastItem = shopList[shopList.length - 1]
      })
    },
    last() {
      this.key++
      this.currentIndex -= 1
      this.currentImg = this.imgList[this.currentIndex].img
      this.currentNumber = this.imgList[this.currentIndex].imgNumber
      this.currenttxt = this.imgList[this.currentIndex].txt
      this.currentTxtone = this.imgList[this.currentIndex].txtone
      this.currentTxttwo = this.imgList[this.currentIndex].txtTwo
      this.currentTxtthree = this.imgList[this.currentIndex].txtThree
      this.currentNumberCircle = this.imgList[this.currentIndex].numberCircle
    },
    next() {
      this.key++
      this.currentIndex += 1
      if (this.currentIndex > this.imgList.length) {
        return
      }
      this.currentImg = this.imgList[this.currentIndex].img
      this.currentNumber = this.imgList[this.currentIndex].imgNumber
      this.currenttxt = this.imgList[this.currentIndex].txt
      this.currentTxtone = this.imgList[this.currentIndex].txtone
      this.currentTxttwo = this.imgList[this.currentIndex].txtTwo
      this.currentTxtthree = this.imgList[this.currentIndex].txtThree
      this.currentNumberCircle = this.imgList[this.currentIndex].numberCircle
    },
    toShop() {
      // this.$router.push({ name: 'shop' })
      getMd5Token().then((res) => {
        if (res.code === 0) {
          window.location.href = `http://${this.lastItem.site}?user_logine=${res.data}&email=${this.userInfo.email}`
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.staticTxt{
  position:absolute;
}
.bottom-btn{
   width: 110px;
   margin-bottom: 50px;
}
.bottom-btn + .bottom-btn{
   margin-left: 50px;
}
.bottom-btn-width{
  width: 200px !important;
}
.main {
  width: 100%;
  max-width: 1920px;
  min-height: calc(100vh - 91px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  &-content {
    display: flex;
    // width: 1263px;
    width: 1463px;
    margin: 70px 120px;
    // border: 1px solid rgb(235, 72, 72);
    &-lbox {
      box-sizing: border-box;
      width: 542px;
      padding-right: 72px;
    //   background-color: aqua;
      &-title {
        margin-top: 60px;
        font-size: 30px;
        font-weight: bold;
        text-align: right;
        &-subtitle {
          text-align: right;
        }
      }
      &-box {
        display: flex;
        // background-color: rgb(231, 149, 149);
        &-pic {
          width: 150px;
          &-draw{
              position: relative;
              // top: 25px;
          }
          &-number{
              position: relative;
              top: -155px;
              left: 30px;
              color: #fff;
              font-size: 80px;
          }
        }
        &-txt {
          text-align: left;
          &-textone {
            margin-top: 67px;
            font-size: 20px;
            font-weight: bold;
          }
          &-texttwo {
              width: 100px;
              border-top: 5px solid #F1881F;
          }
          &-textthree{
              font-size: 12px;
          }
        }
      }
    }
    &-rbox {
    //   background-color: blueviolet;
      &-img {
        margin: 0;
        border: 0;
        width: auto;
        height: auto;
      }
    }
  }
}
@media only screen and (min-width:1700px) and (max-width:1920px){
  .main{
    overflow-x: hidden;
  }
}
</style>
